import { useGetStakeholder } from '@src/api/methods';
import {
  GTIN_NUMBER_DIGIT_MESSAGE,
  GTIN_NUMBER_PATTERN_MESSAGE,
  GTIN_NUMBER_REGEX,
  GTIN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

const statusOptions = [
  { label: 'Depoda', value: 'Depoda' },
  { label: 'Eczanede', value: 'Eczanede' },
  { label: 'Satıldı', value: 'Satıldı' },
  { label: 'Deaktive', value: 'Deaktive' },
  { label: 'Arada', value: 'Arada' },
  { label: 'Problem', value: 'Problem' }
];

const useDateHandling = () => {
  const formatToTurkishDateTimeWithTime = (date) => {
    if (!date) return null;
    return dayjs(date).locale('tr').format('DD.MM.YYYY HH:mm:ss');
  };

  const parseDateInput = (dateInput) => {
    if (!dateInput) return null;

    const formats = [Number.isInteger(+dateInput) ? 'x' : 'DD.MM.YYYY'];
    const parsed = dayjs(dateInput, formats, true);
    return parsed.isValid() ? parsed : null;
  };

  return { formatToTurkishDateTimeWithTime, parseDateInput };
};

const ProductDetailForm = ({
  initialValues,
  onSubmit,
  moduleType,
  onCancel
}) => {
  const { data: stakeholderData } = useGetStakeholder();
  const [isGlnSetting, setIsGlnSetting] = useState(false);
  const [isSellerGlnSetting, setIsSellerGlnSetting] = useState(false);
  const [form] = Form.useForm();

  const { formatToTurkishDateTimeWithTime, parseDateInput } = useDateHandling();

  const handleSaleToGlnBlur = (e) => {
    const gln = e.target.value;
    const stakeholder = stakeholderData.find(
      (stakeholder) => stakeholder.gln === gln
    );

    form.setFieldValue('pharmacySaleToName', stakeholder?.companyName);
    setIsGlnSetting(false);
  };

  const handleSaleToGlnChange = (e) => {
    setIsGlnSetting(true);
  };

  const handleSellerGlnBlur = (e) => {
    const gln = e.target.value;
    const stakeholder = stakeholderData.find(
      (stakeholder) => stakeholder.gln === gln
    );
    form.setFieldValue('sellerName', stakeholder?.companyName);
    setIsSellerGlnSetting(false);
  };

  const handleSellerGlnChange = (e) => {
    setIsSellerGlnSetting(true);
  };

  const baseInitialValues = {
    gtin: initialValues.gtin,
    sn: initialValues.sn,
    productStatus: initialValues.status,
    pharmacyBuyDate: parseDateInput(
      initialValues.product_detail?.pharmacyBuyDate
    ),
    pharmacySaleDate: parseDateInput(
      initialValues.product_detail?.pharmacySaleDate
    )
  };

  const formattedInitialValues =
    moduleType === 'KKI'
      ? {
          ...baseInitialValues,
          sellerGln: initialValues.product_detail?.sellerGln,
          sellerName: initialValues.product_detail?.sellerName,
          pharmacySaleToGln: initialValues.product_detail?.pharmacySaleToGln,
          pharmacySaleToName: initialValues.product_detail?.pharmacySaleToName
        }
      : baseInitialValues;

  const handleSubmit = (values) => {
    const baseValues = {
      requestId: initialValues.requestId,
      gtin: values.gtin,
      sn: values.sn,
      pharmacyBuyDate: formatToTurkishDateTimeWithTime(values.pharmacyBuyDate),
      pharmacySaleDate: formatToTurkishDateTimeWithTime(
        values.pharmacySaleDate
      ),
      productStatus: values.productStatus
    };

    const formattedValues =
      moduleType === 'KKI'
        ? {
            ...baseValues,
            pharmacyKkiProductId: initialValues.pharmacyKkiProductId,
            sellerName: values.sellerName,
            sellerGln: values.sellerGln,
            pharmacySaleToName: values.pharmacySaleToName,
            pharmacySaleToGln: values.pharmacySaleToGln
          }
        : { ...baseValues, pharmacyProductId: initialValues.pharmacyProductId };

    onSubmit(formattedValues);
  };

  return (
    <Form
      className="p-6 flex flex-col"
      form={form}
      initialValues={formattedInitialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        name="productStatus"
        label="İTS Statüsü"
        rules={[{ required: true, message: 'Lütfen ITS statüsü seçiniz' }]}
      >
        <Select options={statusOptions} />
      </Form.Item>

      <Form.Item
        name="gtin"
        label="GTIN"
        rules={[
          {
            required: true,
            message: GTIN_NUMBER_REQUIRED_MESSAGE
          },
          {
            min: 14,
            message: GTIN_NUMBER_DIGIT_MESSAGE
          },
          {
            max: 14,
            message: GTIN_NUMBER_DIGIT_MESSAGE
          },
          {
            pattern: GTIN_NUMBER_REGEX,
            message: GTIN_NUMBER_PATTERN_MESSAGE
          }
        ]}
      >
        <Input placeholder="GTIN giriniz" />
      </Form.Item>
      <Form.Item
        name="sn"
        label="Sıra Numarası"
        rules={[{ required: true, message: 'Lütfen sıra numarası giriniz' }]}
      >
        <Input placeholder="SN giriniz" />
      </Form.Item>

      <Form.Item
        className="flex w-full items-start"
        name="pharmacyBuyDate"
        label="Alış Tarihi"
      >
        <DatePicker
          showTime
          format="DD.MM.YYYY HH:mm:ss"
          placeholder="Alış tarihi seçiniz"
        />
      </Form.Item>

      <Form.Item
        className="flex w-full items-start"
        name="pharmacySaleDate"
        label="Satış Tarihi"
      >
        <DatePicker
          showTime
          format="DD.MM.YYYY HH:mm:ss"
          placeholder="Satış tarihi seçiniz"
        />
      </Form.Item>

      {moduleType === 'KKI' && (
        <>
          <Form.Item
            name="pharmacySaleToName"
            label="Satış Tipi"
            className={isGlnSetting ? 'opacity-50' : ''}
          >
            <Input placeholder="Satış Tipi giriniz" disabled={isGlnSetting} />
          </Form.Item>
          <Form.Item name="pharmacySaleToGln" label="Satış Tipi GLN">
            <Input
              placeholder="Satış Tipi GLN giriniz"
              onBlur={handleSaleToGlnBlur}
              onChange={handleSaleToGlnChange}
              onClick={() => setIsGlnSetting(true)}
            />
          </Form.Item>
          <Form.Item
            name="sellerName"
            label="Depo Adı"
            className={isSellerGlnSetting ? 'opacity-50' : ''}
          >
            <Input placeholder="Depo Adı" disabled={isSellerGlnSetting} />
          </Form.Item>
          <Form.Item name="sellerGln" label="Depo GLN ">
            <Input
              placeholder="Depo GLN giriniz"
              onBlur={handleSellerGlnBlur}
              onChange={handleSellerGlnChange}
              onClick={() => setIsSellerGlnSetting(true)}
            />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <div className="flex justify-end gap-2">
          <Button onClick={onCancel}>İptal</Button>
          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default ProductDetailForm;
