import { env } from '@src/env';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiInstance } from './axiosConfig';

export const backendBaseUrl = env.REACT_APP_BACKEND_URL;

export const useGet = (url) => {
  return useQuery({
    queryKey: [url],
    queryFn: () => apiInstance.get(url).then((res) => res.data),
    refetchOnWindowFocus: false
  });
};

export const usePost = (url) => {
  const mutation = useMutation({
    mutationKey: [url],
    mutationFn: async (payload) => {
      try {
        const response = await apiInstance.post(url, payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  });

  return mutation;
};

export const usePatch = (url, payload) => {
  return useMutation({
    mutationKey: [url],
    mutationFn: () => apiInstance.patch(url, payload)
  });
};

export const useDelete = (url, id) => {
  return useMutation({
    mutationKey: [url],
    mutationFn: () => apiInstance.delete(`${url + id}`)
  });
};

export const useDeleteWithBody = (url, id, body) => {
  return useMutation({
    mutationKey: [url],
    mutationFn: () =>
      apiInstance.delete(`${backendBaseUrl}${url + id}`, { data: body })
  });
};

export const useUpdateProductDetail = () => {
  return useMutation({
    mutationKey: ['updateProductDetail'],
    mutationFn: async (payload) => {
      try {
        const response = await apiInstance.post(
          '/admin/product-detail',
          payload
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  });
};

export const getProductImage = async ({ productId, requestId }) => {
  const response = await apiInstance.post(
    'product-image',
    {
      productId,
      requestId
    },
    {
      responseType: 'blob'
    }
  );

  return response.data;
};

export const useGetProductImage = () => {
  return useMutation({
    mutationKey: ['product', 'image'],
    mutationFn: (data) => getProductImage(data)
  });
};

export const useGetStakeholder = () => {
  return useQuery({
    queryKey: ['allStakeholder'],
    queryFn: () => getStakeholder(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });
};

export const getStakeholder = async () => {
  try {
    const response = await apiInstance.get(`admin/stakeholder`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// export const usePostPublic = (url, payload) => {
//   return useMutation(
//     url,
//     () => apiInstance.post(`${backendBaseUrl}${url}`, payload),
//     {
//       onSuccess: (data) => {
//         // handle success
//       },
//       onError: (error) => {
//         // handle error
//       }
//     }
//   );
// };

// export const useGetPublic = (url) => {
//   return useQuery(url, () => apiInstance.get(`${backendBaseUrl}${url}`), {
//     staleTime: 10000 // 10 seconds
//   });
// };
