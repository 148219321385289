import {
  useGet,
  useGetProductImage,
  usePost,
  useUpdateProductDetail
} from '@src/api/methods';
import { queryClient } from '@src/App';
import CloseIcon from '@src/components/shared/CloseIcon/CloseIcon';
import { statusMap } from '@src/utils/staticJsonObjects/status';
import { formatDateToTurkish } from '@utils/date.helpers';
import { formatTurkishCurrency } from '@utils/numberFormats';
import { Collapse, Image, message, Modal, Popover, Spin, Tag } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PiWarningCircleFill } from 'react-icons/pi';
import './ApplicationDetailModal.scss';
import ApplicationWholesalerUpdate from './ApplicationWholesalerUpdate';
import AcceptedBarcodeTable from './Tables/AcceptedBarcodeTable';
import ProductDetailForm from './Tables/ProductDetailForm';
import RejectedBarcodeTable from './Tables/RejectedBarcodeTable';
const ModuleType = {
  STOCK: 'STOCK',
  KKI: 'KKI',
  PAP: 'PAP'
};

const RequestStatus = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  PROBLEM: 'PROBLEM',
  CANCELED: 'CANCELED',
  WAITING: 'WAITING'
};

function getUrl(requestId, pageType, type) {
  if (pageType === 'COMPANY') {
    return type === 'KKI'
      ? `/companypages/request-detail-kki/${requestId}`
      : `/companypages/request-detail-stock/${requestId}`;
  }
  if (pageType === 'ADMIN') {
    return type === 'KKI'
      ? `/admin/request-detail-kki/${requestId}`
      : `/admin/request-detail-stock/${requestId}`;
  }
}

/**
 * Utility function to filter accepted barcodes.
 */
const getAcceptedBarcodeList = (productList) => {
  return productList.filter((product) => product.product_problem === null);
};

/**
 * Utility function to filter rejected barcodes.
 */
const getRejectedBarcodeList = (productList) => {
  return productList.filter((product) => product.product_problem !== null);
};

/**
 * Utility function to render status tags.
 */
const handleStatusTags = (status, totalPayment) => {
  if (!status) {
    return '-';
  }
  const statusElementMap = new Map([
    [RequestStatus.PENDING, ['#CAE9FF', '#44A5FF']],
    [RequestStatus.ACCEPTED, ['#C5EDBF', '#387947']],
    [RequestStatus.REJECTED, ['#FFD8DA', '#C41A1A']],
    [RequestStatus.PROBLEM, ['#FFD8DA', '#C41A1A']],
    [RequestStatus.CANCELED, ['#C4C4C4', '#25233C']],
    [RequestStatus.WAITING, ['#FFF2C3', '#EBB322']]
  ]);

  const effectiveStatus =
    status === RequestStatus.PENDING &&
    (totalPayment === 0 || totalPayment === null || totalPayment === undefined)
      ? RequestStatus.WAITING
      : status;

  const statusInfo = statusElementMap.get(effectiveStatus);

  return (
    <Tag
      className="ml-2 text-lg"
      color={statusInfo[0]}
      style={{ color: statusInfo[1] }}
    >
      {effectiveStatus === RequestStatus.WAITING
        ? 'Tamamlanmadı'
        : statusMap.get(effectiveStatus)}
    </Tag>
  );
};

const AdminApplicationDetailModal = ({
  application,
  isModalVisible,
  onCloseModal,
  pageType,
  moduleType
}) => {
  const [acceptedBarcodeList, setAcceptedBarcodeList] = useState(null);
  const [rejectedBarcodeList, setRejectedBarcodeList] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  console.log('editingProduct', editingProduct);

  // Update state management for image preview
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);

  const [activeKey, setActiveKey] = useState([]);

  const { requestId } = application;

  const { data: applicationDetail } = useGet(
    getUrl(requestId, pageType, moduleType === ModuleType.KKI ? 'KKI' : 'STOCK')
  );
  console.log('applicationDetail', applicationDetail);

  const { mutate: cancelApplication, isPending: isCancelling } = usePost(
    `request-cancel/${requestId}`
  );

  const { mutate: updateProductDetail } = useUpdateProductDetail();
  const { mutate: getProductImage } = useGetProductImage();

  // Updated image handling functions
  const handleImageRequest = useCallback(
    (productId, action) => {
      if (currentImageUrl) {
        URL.revokeObjectURL(currentImageUrl);
        setCurrentImageUrl(null);
      }

      getProductImage(
        {
          productId: productId,
          requestId: application.requestId
        },
        {
          onSuccess: async (response) => {
            const imageUrl = URL.createObjectURL(response);

            if (action === 'download') {
              const link = document.createElement('a');
              link.href = imageUrl;
              link.download = `product-${productId}-image.jpg`;
              link.click();
              // Cleanup object URL after download
              setTimeout(() => URL.revokeObjectURL(imageUrl), 100);
            } else {
              // For preview, store the URL in state and show preview
              setCurrentImageUrl(imageUrl);
              setPreviewVisible(true);
            }
          },
          onError: async (error) => {
            console.error('Image fetch error:', error);
            message.error('Görüntü yüklenemedi');
          }
        }
      );
    },
    [getProductImage, application.requestId, currentImageUrl]
  );

  // Cleanup image URLs when modal closes
  useEffect(() => {
    if (!isModalVisible || !previewVisible) {
      if (currentImageUrl) {
        URL.revokeObjectURL(currentImageUrl);
        setCurrentImageUrl(null);
      }
      setPreviewVisible(false);
    }
  }, [isModalVisible, previewVisible, currentImageUrl]);

  // Handler for collapse changes
  const handleCollapseChange = (keys) => {
    setActiveKey(Array.isArray(keys) ? keys : [keys]);
  };

  // Initialize barcode lists and activeKey based on application details
  useEffect(() => {
    if (applicationDetail) {
      if (moduleType === ModuleType.KKI) {
        const kkiDetails = applicationDetail;
        setAcceptedBarcodeList(
          getAcceptedBarcodeList(kkiDetails.pharmacy_kki_products)
        );
        setRejectedBarcodeList(
          getRejectedBarcodeList(kkiDetails.pharmacy_kki_products)
        );
      } else {
        const stockDetails = applicationDetail;
        setAcceptedBarcodeList(
          getAcceptedBarcodeList(stockDetails.pharmacy_products)
        );
        setRejectedBarcodeList(
          getRejectedBarcodeList(stockDetails.pharmacy_products)
        );
      }
    }

    // Cleanup on unmount
    return () => {
      setAcceptedBarcodeList(null);
      setRejectedBarcodeList(null);
      setActiveKey([]);
    };
  }, [applicationDetail, moduleType]);

  // Initialize activeKey based on rejectedBarcodeList
  useEffect(() => {
    if (rejectedBarcodeList && rejectedBarcodeList.length > 0) {
      setActiveKey(['2']); // Open Rejected Barcodes panel by default
    } else {
      setActiveKey([]); // No panels open
    }
  }, [rejectedBarcodeList]);

  const handleEditProduct = useCallback((product) => {
    setEditingProduct(product);
  }, []);

  const handleUpdateProduct = useCallback(
    (values) => {
      updateProductDetail(values, {
        onSuccess: () => {
          message.success('Ürün detayları başarıyla güncellendi');
          setEditingProduct(null);
          // Refresh the data
          queryClient.invalidateQueries([
            getUrl(
              requestId,
              pageType,
              moduleType === ModuleType.KKI ? 'KKI' : 'STOCK'
            )
          ]);
        },
        onError: () => {
          message.error('Ürün detayları güncellenirken bir hata oluştu');
        }
      });
    },
    [updateProductDetail, moduleType, pageType, requestId]
  );

  /**
   * Generates collapse items for accepted and rejected barcodes.
   */
  const CollapseItems = useCallback(
    (panelStyle) => [
      {
        key: '1',
        label: (
          <div className="flex items-center ">
            <span className="text-lg font-bold text-white bg-green rounded-t-md px-2 py-1 w-72">
              Geçerli karekodlar: {acceptedBarcodeList?.length} adet
            </span>
          </div>
        ),
        children: acceptedBarcodeList ? (
          <>
            <AcceptedBarcodeTable
              productList={acceptedBarcodeList}
              moduleType={moduleType}
              onEdit={
                pageType === 'ADMIN' &&
                application.requestStatus !== RequestStatus.ACCEPTED
                  ? handleEditProduct
                  : undefined
              }
              handleImageRequest={handleImageRequest}
            />
            {editingProduct && (
              <Modal
                title="Ürün Detaylarını Düzenle"
                open={!!editingProduct}
                footer={null}
                onCancel={() => setEditingProduct(null)}
                closeIcon={<CloseIcon className="h-7 w-7 cursor-pointer" />}
                className="modal"
              >
                <ProductDetailForm
                  initialValues={{
                    ...editingProduct,
                    requestId: application.requestId
                  }}
                  onSubmit={handleUpdateProduct}
                  onCancel={() => setEditingProduct(null)}
                  moduleType={moduleType}
                />
              </Modal>
            )}
          </>
        ) : (
          <Spin />
        ),
        style: panelStyle,
        collapsible: acceptedBarcodeList?.length === 0 ? 'disabled' : 'header'
      },
      {
        key: '2',
        label: (
          <div className="flex items-center ">
            <span className="text-lg font-bold text-white bg-red rounded-t-md px-2 py-1 w-72">
              Geçersiz karekodlar: {rejectedBarcodeList?.length} adet
            </span>
          </div>
        ),
        children: rejectedBarcodeList ? (
          <>
            <RejectedBarcodeTable
              productList={rejectedBarcodeList}
              moduleType={moduleType}
              onEdit={
                pageType === 'ADMIN' &&
                application.requestStatus !== RequestStatus.ACCEPTED
                  ? handleEditProduct
                  : undefined
              }
              handleImageRequest={handleImageRequest}
            />
            {editingProduct && (
              <Modal
                title="Ürün Detaylarını Düzenle"
                open={!!editingProduct}
                footer={null}
                onCancel={() => setEditingProduct(null)}
                closeIcon={<CloseIcon className="h-7 w-7 cursor-pointer" />}
                className="modal"
              >
                <ProductDetailForm
                  initialValues={{
                    ...editingProduct,
                    requestId: application.requestId
                  }}
                  onSubmit={handleUpdateProduct}
                  onCancel={() => setEditingProduct(null)}
                  moduleType={moduleType}
                />
              </Modal>
            )}
          </>
        ) : (
          <Spin />
        ),
        style: panelStyle,
        collapsible: rejectedBarcodeList?.length === 0 ? 'disabled' : 'header'
      }
    ],
    [
      acceptedBarcodeList,
      rejectedBarcodeList,
      moduleType,
      pageType,
      editingProduct,
      handleEditProduct,
      handleUpdateProduct,
      application.requestStatus,
      application.requestId,
      handleImageRequest
    ]
  );

  const panelItemStyle = {
    marginBottom: 24
  };

  /**
   * Retrieves the application month and year.
   */
  const getApplicationMonthAndYear = useCallback(() => {
    const date = new Date(parseInt(application.applicationDate));
    return [date.getMonth() + 1, date.getFullYear()];
  }, [application]);

  /**
   * Checks if the application is in the current month and year.
   */
  const isSameMonth = useCallback(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const appMonthAndYear = getApplicationMonthAndYear();

    if (!appMonthAndYear) return false;

    const [appMonth, appYear] = appMonthAndYear;
    return appMonth === currentMonth && appYear === currentYear;
  }, [getApplicationMonthAndYear]);

  /**
   * Handles the cancellation of an application with confirmation.
   */
  const handleCancelApplication = useCallback(() => {
    Modal.confirm({
      title: (
        <div className="flex gap-2">
          <span className="bg-slate-500 text-white px-2 py-1 rounded ">
            {application?.requestId}
          </span>
          <div className="text-lg font-bold items-center py-1">
            <span className="tracking-wide ">No.lu Başvuru İptali</span>
          </div>
        </div>
      ),
      content: (
        <div className="my-2">
          <div className="flex gap-2">
            <p>Başvurunuzu iptal etmek istediğinize emin misiniz?</p>
          </div>
        </div>
      ),
      okText: 'Evet',
      cancelText: 'Hayır',
      okButtonProps: {
        className: 'bg-normalPurple'
      },
      onOk: () => {
        cancelApplication({
          onSuccess: () => {
            message.success('Başvuru iptal edildi', 5);
            onCloseModal(false);
            queryClient.invalidateQueries({
              queryKey: ['pharmacypages/requests']
            });
          },
          onError: () => {
            message.error('Başvuru iptal edilemedi', 5);
          }
        });
      },
      centered: true
    });
  }, [application, cancelApplication, onCloseModal]);

  const isCancellable = useMemo(() => {
    return pageType === 'ADMIN'
      ? true
      : isSameMonth() &&
          application?.requestStatus === RequestStatus.PENDING &&
          application.totalPayment !== 0;
  }, [
    pageType,
    isSameMonth,
    application?.requestStatus,
    application.totalPayment
  ]);

  /**
   * Renders the cancel button with conditional styling and functionality.
   */
  const cancelButtonRender = useMemo(() => {
    const buttonContent = (
      <div
        className={`
					bg-lightPurple2 max-w-40 mr-16 rounded-b-md flex justify-center items-center px-4
					${
            isCancellable
              ? 'hover:bg-purple-100 hover:border-b-2 cursor-pointer'
              : 'opacity-40 hover:cursor-not-allowed'
          }
				`}
        onClick={() => isCancellable && handleCancelApplication()}
      >
        {isCancelling ? (
          <Spin />
        ) : (
          <span
            className={`text-darkPurple text-lg font-bold break-words ml-6 mr-4 ${
              !isCancellable && 'opacity-40'
            }`}
          >
            Başvuru iptali için tıklayınız
          </span>
        )}
      </div>
    );

    return isCancellable ? (
      buttonContent
    ) : (
      <Popover content="Geçmiş aya ait başvurular ve/veya durumu 'Reddedildi' ya da 'Ödendi' olan başvurular iptal edilememektedir.">
        {buttonContent}
      </Popover>
    );
  }, [isCancelling, isCancellable, handleCancelApplication]);

  return (
    <Modal
      key="application-detail-modal"
      className="application-detail-modal"
      open={isModalVisible}
      closeIcon={<CloseIcon className="h-9 w-9 cursor-pointer" />}
      footer={null}
      width="90%"
      onCancel={() => onCloseModal(false)}
      destroyOnClose
    >
      <div className="px-4 flex flex-col gap-3">
        {/* Header Section */}
        <div className="flex justify-between -ml-10 -mt-5">
          <div className="bg-normalPurple rounded-tl-md rounded-br-md w-4/6 pr-20">
            <ul className="text-white flex justify-between px-9 py-4 text-xl 2xl:text-lg 3xl:text-xl m-0">
              <li className="flex gap-2 flex-col items-center">
                <span>Başvuru No.</span>
                <span>{application?.requestId}</span>
              </li>
              <li className="flex gap-1 flex-col items-center">
                <span>Durum</span>
                <span>
                  {handleStatusTags(
                    application?.requestStatus,
                    application?.totalPayment
                  )}
                </span>
              </li>
              <li className="flex gap-2 flex-col items-center">
                <span>Başvuru Tarihi</span>
                <span>
                  {application?.applicationDate
                    ? formatDateToTurkish(
                        application.applicationDate,
                        pageType === 'ADMIN'
                      )
                    : '-'}
                </span>
              </li>
              <li className="flex gap-2 flex-col items-center">
                <span>Ödeme Tutarı (KDV Hariç)</span>
                <span>
                  {application?.totalPayment
                    ? formatTurkishCurrency(application.totalPayment, 2)
                    : '-'}
                </span>
              </li>
            </ul>
          </div>
          {pageType === 'ADMIN' ? cancelButtonRender : null}
        </div>

        {/* Request Note */}
        <div className="w-full text-lg mt-1">
          {application.requestStatus === RequestStatus.PENDING &&
          application.totalPayment > 0
            ? `Deponuz sizinle geri ödeme için ${
                getApplicationMonthAndYear()?.[0] === 12
                  ? 1
                  : getApplicationMonthAndYear()?.[0] + 1
              }. ayda iletişime geçecektir.`
            : application.requestStatus === RequestStatus.PENDING &&
              (application.totalPayment === 0 ||
                application.totalPayment === null ||
                application.totalPayment === undefined)
            ? 'İTS sorgu sırasında karşılaşılan yavaşlık sebebiyle başvuru sonucunuz henüz belli değildir. Gün içinde tekrar kontrol ediniz. Başvurunuz geçerli olursa "Doğrulandı" statüsüne geçecek, geçersiz ise otomatik olarak reddedilecektir. Başvurunuzun reddedilmesi durumunda reddedilme sebebini başvurunuzun üzerine tıklayarak görüntüleyebilirsiniz.'
            : applicationDetail?.requestNote}
        </div>

        {/* Divider */}
        {(applicationDetail?.requestNote ||
          application.requestStatus === RequestStatus.PENDING) && (
          <div className="w-full h-[1px] bg-gray-200 my-2" />
        )}

        {/* Wholesaler Update Section */}
        {moduleType !== ModuleType.KKI && (
          <div className="flex flex-col gap-2 w-full">
            {pageType === 'ADMIN' ? (
              applicationDetail ? (
                <ApplicationWholesalerUpdate
                  isChangeable={isCancellable}
                  companyId={applicationDetail.module.company.companyId}
                  requestId={applicationDetail.requestId}
                  wholesalerName={application.wholesaler?.name ?? ''}
                />
              ) : (
                <div className="flex justify-center items-center">
                  <Spin />
                </div>
              )
            ) : null}

            {moduleType === ModuleType.PAP && applicationDetail && (
              <div className="flex flex-row">
                <span className="flex items-center text-start w-40 h-11 pl-2 bg-lightPurple3 text-lg rounded-md mr-2 text-white">
                  İndirim Kodu:
                </span>
                <span className="bg-gray-100 flex items-center rounded-md px-2 py-1 text-lg">
                  {applicationDetail?.pap_requests?.[0]?.pap_code.code || '-'}
                </span>
              </div>
            )}
          </div>
        )}

        {/* Divider */}
        {moduleType !== ModuleType.KKI && pageType === 'ADMIN' && (
          <div className="w-full h-[1px] bg-gray-200 my-2" />
        )}

        {/* Collapse Section for Barcode Tables */}
        <Collapse
          ghost
          expandIconPosition="end"
          items={CollapseItems(panelItemStyle)}
          activeKey={activeKey}
          onChange={handleCollapseChange}
          expandIcon={({ isActive, ...panelProps }) => {
            const panelKey = panelProps.panelKey;
            const hasContent =
              (panelKey === '2' &&
                rejectedBarcodeList &&
                rejectedBarcodeList.length > 0) ||
              (panelKey === '1' &&
                acceptedBarcodeList &&
                acceptedBarcodeList.length > 0);
            return hasContent ? (
              <span>
                <span className="text-cyan text-sm">
                  {isActive ? 'Detayları Gizle' : 'Detayları Göster'}
                </span>
              </span>
            ) : null;
          }}
        />

        {/* Conditional Divider */}
        {pageType === 'ADMIN' && isCancellable && (
          <div className="w-full h-[1px] bg-gray-200 my-2" />
        )}

        {/* Warning Message */}
        {pageType === 'ADMIN' && isCancellable && (
          <div className="flex gap-2">
            <PiWarningCircleFill className="text-blue-500" size={22} />
            Başvuru içindeki ürün adedini değiştirmek: Başvuru içeriğinde
            karekod bazlı silme/iptal yapılamamaktadır. Başvurunuzu sağ üst
            köşedeki kutucuğa tıklayıp iptal ederek doğru ürünlerle tekrar
            başvuru oluşturabilirsiniz.
          </div>
        )}
      </div>

      {/* Associated with current row of barcodes tables  */}
      {/* Update Image preview component */}
      <div style={{ display: 'none' }}>
        <Image
          preview={{
            visible: previewVisible,
            src: currentImageUrl ?? '',
            onVisibleChange: (visible) => {
              setPreviewVisible(visible);
              if (!visible && currentImageUrl) {
                URL.revokeObjectURL(currentImageUrl);
                setCurrentImageUrl(null);
              }
            }
          }}
          src={currentImageUrl ?? ''}
        />
      </div>
    </Modal>
  );
};

export default AdminApplicationDetailModal;
