import { useGet, usePost } from '@src/api/methods';
import { Button, Form, Input, Switch, Table, Tag, message } from 'antd';
const { TextArea } = Input;

export default function ApplicationStatus() {
  const { data, isLoading, refetch } = useGet('/application/status');
  const { mutate: createRequest } = usePost('/application/status');
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    values.status = values.status ? 'ACTIVE' : 'INACTIVE';
    createRequest(values, {
      onSuccess: () => {
        refetch();
        form.resetFields();
        message.success('Başvuru durumu başarıyla güncellendi.');
      },
      onError: () => {
        message.error('Başvuru durumu güncellenirken bir hata oluştu!');
      }
    });
  };

  const columns = [
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        const statusText =
          text === 'ACTIVE' ? 'Ekran görüntüsü aktif' : 'İTS girişi aktif';
        return (
          <Tag color={text === 'ACTIVE' ? 'green' : 'red'}>{statusText}</Tag>
        );
      },
      width: '10%'
    },
    {
      title: 'Başlık',
      dataIndex: 'title',
      key: 'title',
      width: '20%'
    },
    {
      title: 'Mesaj',
      dataIndex: 'message',
      key: 'message'
    }
  ];

  return (
    <div>
      <div>
        <h3 className="kemi_form_highlighted_header">Mevcut Durum</h3>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={[data]}
          pagination={false}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <h3 className="kemi_form_highlighted_header">Yeni Durum </h3>

        <Form onFinish={handleSubmit} form={form}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '50%'
            }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                label="Başlık"
                name="title"
                rules={[{ required: true, message: 'Başlık giriniz!' }]}
                style={{ width: '85%' }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Durum"
                name="status"
                valuePropName="checked"
                initialValue="ACTIVE"
                tooltip="Ekran görüntüsü aktif: Sistem ekran görüntüsü yüklemeye açık | İTS girişi aktif: Sistem İTS girişine açık"
              >
                <Switch
                  checkedChildren="Ekran görüntüsü aktif"
                  unCheckedChildren="İTS girişi aktif"
                  defaultChecked
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Mesaj"
              name="message"
              rules={[{ required: true, message: 'Mesaj giriniz!' }]}
            >
              <TextArea />
            </Form.Item>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <span
                style={{
                  fontSize: '12px',
                  color: 'gray',
                  marginRight: '10px'
                }}
              >
                Mevcut durumun üzerine yazılacak.
              </span>

              <Form.Item style={{ marginBottom: '0px' }}>
                <Button type="primary" htmlType="submit">
                  Kaydet
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
