import { DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { formatDateToTurkish } from '@utils/date.helpers';
import { Button } from 'antd';

const ModuleType = {
  STOCK: 'STOCK',
  KKI: 'KKI',
  PAP: 'PAP'
};

const RejectedBarcodeTable = ({
  productList,
  moduleType,
  onEdit,
  handleImageRequest
}) => {
  console.log(productList);
  const decideTable = () => {
    // STOCK AND PAP
    if (moduleType !== ModuleType.KKI) {
      return (
        <div className="relative w-full">
          <div className="overflow-x-auto max-w-full bg-softRed">
            <div className="min-w-[1300px] w-full ">
              <table className=" w-full table-fixed">
                <thead>
                  <tr className="flex gap-3 2xl:gap-3 3xl:gap-5 px-5 text-base 2xl:text-base 3xl:text-lg mt-4">
                    {onEdit && <th className="w-3 text-start -ml-1"></th>}
                    <th
                      className={`w-[30%] 2xl:w-[30%] 3xl:w-[30%] text-start`}
                    >
                      İlaç Karekod
                    </th>
                    <th className="w-[7%] 2xl:w-[7%] 3xl:w-[7%] text-start">
                      Alış Tarihi
                    </th>
                    <th className="w-[7%] 2xl:w-[7%] 3xl:w-[7%] text-start">
                      Satış Tarihi
                    </th>
                    <th className="w-[7%] 2xl:w-[7%] 3xl:w-[7%] text-start">
                      İTS Statüsü
                    </th>
                    <th className="w-[30%] 2xl:w-[35%] 3xl:w-[35%] min-w-[600px] text-ellipsis whitespace-nowrap text-start">
                      Reddedilme Sebebi
                    </th>
                    <th className="w-[3%] ml-auto"></th>
                  </tr>
                </thead>

                <tbody>
                  {productList.map((product, index) => (
                    <tr
                      key={product.barcode}
                      className={`${
                        index === 0 ? 'mt-2' : ''
                      } flex gap-3 2xl:gap-3 3xl:gap-5 px-5 mb-2 text-sm 2xl:text-sm 3xl:text-base whitespace-nowrap`}
                    >
                      {onEdit && (
                        <td className="w-3 flex justify-center items-center -ml-1">
                          <Button
                            className="w-3 h-3"
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => onEdit(product)}
                          />
                        </td>
                      )}

                      <td
                        className={`w-[30%] 2xl:w-[30%] 3xl:w-[30%]`}
                        title={product.barcode}
                      >
                        <span className="bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap text-sm">
                          {product.barcode}
                        </span>
                      </td>

                      <td className="w-[7%] 2xl:w-[7%] 3xl:w-[7%]">
                        {product?.product_detail?.pharmacyBuyDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacyBuyDate
                            )
                          : '-'}
                      </td>

                      <td className="w-[7%] 2xl:w-[7%] 3xl:w-[7%]">
                        {product?.product_detail?.pharmacySaleDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacySaleDate
                            )
                          : '-'}
                      </td>

                      <td className="w-[7%] 2xl:w-[7%] 3xl:w-[7%]">
                        {product?.status}
                      </td>

                      <td className="w-[35%] 2xl:w-[35%] 3xl:w-[35%] min-w-[600px] text-ellipsis whitespace-nowrap">
                        <span className="py-1 text-ellipsis whitespace-nowrap text-sm">
                          {product?.product_problem?.note}
                        </span>
                      </td>

                      <td className="w-[3%] ml-auto flex gap-2 pt-1">
                        <Button
                          rootClassName="border-none"
                          className="bg-transparent w-3 h-3"
                          onClick={() => {
                            handleImageRequest(
                              product.pharmacyProductId,
                              'download'
                            );
                          }}
                          disabled={!product.hasImage}
                          icon={
                            <DownloadOutlined
                              style={{
                                opacity: `${product.hasImage ? 1 : 0.5}`
                              }}
                            />
                          }
                        />
                        <Button
                          rootClassName="border-none"
                          className="bg-transparent w-3 h-3"
                          onClick={() => {
                            handleImageRequest(
                              product.pharmacyProductId,
                              'preview'
                            );
                          }}
                          disabled={!product.hasImage}
                          icon={
                            <EyeOutlined
                              style={{
                                opacity: `${product.hasImage ? 1 : 0.5}`
                              }}
                            />
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else {
      // KKI
      return (
        <div className="relative w-full">
          <div className="overflow-x-auto max-w-full bg-softRed">
            <div className="min-w-[1400px] w-full">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="flex gap-2 3xl:gap-3 px-2 mb-2 text-base 3xl:text-base mt-4 whitespace-nowrap">
                    {onEdit && <th className="w-2 flex pr-2"></th>}
                    <th className="w-[29%] 2xl:w-[29%] 3xl:w-[29%] min-w-[450px]  text-start">
                      İlaç Karekod
                    </th>
                    <th className="w-[6%] 2xl:w-[6%] 3xl:w-[6%] min-w-[75px] text-start">
                      Alış Tarihi
                    </th>
                    <th className="w-[6%] 2xl:w-[6%] 3xl:w-[6%] min-w-[75px] text-start">
                      Satış Tarihi
                    </th>
                    <th className="w-[6%] 2xl:w-[6%] 3xl:w-[6%] min-w-[75px] text-start">
                      İTS Statüsü
                    </th>
                    <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] min-w-[100px] text-start">
                      Satış Tipi GLN
                    </th>
                    <th className="w-[13%] 2xl:w-[13%] 3xl:w-[13%] min-w-[150px] text-start">
                      Satış Tipi
                    </th>
                    <th className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] min-w-[100px] text-start">
                      Depo GLN
                    </th>
                    <th className="w-[16%] 2xl:w-[16%] 3xl:w-[16%] min-w-[150px] text-start">
                      Depo
                    </th>
                    <th className="w-[16%] 2xl:w-[16%] 3xl:w-[16%] min-w-[600px] text-ellipsis whitespace-nowrap text-start">
                      Reddedilme Sebebi
                    </th>
                    <th className="w-[3%] ml-auto"></th>
                  </tr>
                </thead>

                <tbody>
                  {productList.map((product, index) => (
                    <tr
                      key={product.barcode}
                      className={`${
                        index === 0 ? 'mt-2' : ''
                      } flex gap-2 3xl:gap-3 px-2 mb-2 text-sm  whitespace-nowrap`}
                    >
                      {onEdit && (
                        <td className="w-2 flex pt-1 -ml-1 pr-1">
                          <Button
                            className="w-3 h-3"
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => onEdit(product)}
                          />
                        </td>
                      )}

                      {/* İlaç Karekod */}
                      <td
                        className={`w-[29%] 2xl:w-[29%] 3xl:w-[29%] min-w-[450px]`}
                        title={product.barcode}
                      >
                        <span className="bg-white rounded-md px-2 py-1 overflow-hidden whitespace-nowrap">
                          {product.barcode}
                        </span>
                      </td>

                      {/* Alış Tarihi */}
                      <td className="w-[6%] 2xl:w-[6%] 3xl:w-[6%] min-w-[75px]">
                        {product?.product_detail?.pharmacyBuyDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacyBuyDate
                            )
                          : '-'}
                      </td>

                      {/* Satış Tarihi */}
                      <td className="w-[6%] 2xl:w-[6%] 3xl:w-[6%] min-w-[75px]">
                        {product?.product_detail?.pharmacySaleDate
                          ? formatDateToTurkish(
                              +product.product_detail.pharmacySaleDate
                            )
                          : '-'}
                      </td>

                      {/* İTS Statüsü */}
                      <td className="w-[6%] 2xl:w-[6%] 3xl:w-[6%] min-w-[75px]">
                        {product?.status ?? '-'}
                      </td>

                      {/* SATIŞ TİPİ GLN */}
                      <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] min-w-[100px]">
                        {product?.product_detail?.pharmacySaleToGln ?? '-'}
                      </td>

                      {/* SATIŞ TİPİ */}
                      <td
                        className="w-[13%] 2xl:w-[13%] 3xl:w-[13%] min-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap"
                        title={
                          product?.product_detail?.pharmacySaleToName ?? '-'
                        }
                      >
                        {product?.product_detail?.pharmacySaleToName ?? '-'}
                      </td>

                      {/* DEPO GLN */}
                      <td className="w-[8%] 2xl:w-[8%] 3xl:w-[8%] min-w-[100px]">
                        {product?.product_detail?.sellerGln ?? '-'}
                      </td>

                      {/* DEPO */}
                      <td
                        className="w-[16%] 2xl:w-[16%] 3xl:w-[16%] min-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap"
                        title={
                          product?.product_detail?.sellerName
                            ? product?.product_detail?.sellerName
                            : '-'
                        }
                      >
                        {product?.product_detail?.sellerName
                          ? product?.product_detail?.sellerName
                          : '-'}
                      </td>

                      {/* Reddedilme Sebebi */}
                      <td className="w-[16%] 2xl:w-[16%] 3xl:w-[16%] min-w-[600px] text-ellipsis whitespace-nowrap">
                        <span className="py-1 text-ellipsis whitespace-nowrap text-sm text-start">
                          {product?.product_problem?.note ?? '-'}
                        </span>
                      </td>
                      <td className="w-[3%] ml-auto flex gap-2">
                        <Button
                          rootClassName="border-none"
                          className="bg-transparent w-3 h-3"
                          onClick={() => {
                            handleImageRequest(
                              product.pharmacyKkiProductId,
                              'download'
                            );
                          }}
                          disabled={!product.hasImage}
                          icon={
                            <DownloadOutlined
                              style={{
                                opacity: `${product.hasImage ? 1 : 0.5}`
                              }}
                            />
                          }
                        />
                        <Button
                          rootClassName="border-none"
                          className="bg-transparent w-3 h-3 mb-1"
                          onClick={() => {
                            handleImageRequest(
                              product.pharmacyKkiProductId,
                              'preview'
                            );
                          }}
                          disabled={!product.hasImage}
                          icon={
                            <EyeOutlined
                              style={{
                                opacity: `${product.hasImage ? 1 : 0.5}`
                              }}
                            />
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  };

  return decideTable();
};

export default RejectedBarcodeTable;
